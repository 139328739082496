<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <!-- Queue -->
    <b-row class="fade-in-effect" v-if="!full_screen">
      <b-col>
        <b-card>
          <span>{{ lang("t_queue") }}</span>
          <v-select
            class="mt-50"
            v-model="selected_merged_queues"
            :reduce="(option) => option"
            label="display_name"
            value="internal_name"
            :options="merged_queues"
            :placeholder="lang('t_queue')"
            multiple
            deselectFromDropdown
            :closeOnSelect="false"
          >
            <template #option="{ internal_name, _type }">
              <div class="d-flex align-items-center"></div>
              <span v-if="_type == 'queue'" class="" style="margin: 0">{{
                merged_queues.find((e) => e.internal_name == internal_name)
                  .display_name
              }}</span>
              <span
                v-b-tooltip.hover.bottom="
                  queue_groups.find((e) => e.internal_name)
                    ? queue_groups
                        .find((e) => e.internal_name)
                        .queues.map((e) => e.display_name)
                        .join(' - ')
                    : ''
                "
                v-else
                class="font-weight-bolder"
                style="margin: 0"
                >{{
                  merged_queues.find((e) => e.internal_name == internal_name)
                    .display_name
                }}</span
              >
              <small v-if="_type == 'queue_group'" class="text-muted ml-1">
                {{ lang(`t_queueGroup`) }}</small
              >
              <small v-if="_type == 'agent_group'" class="text-muted ml-1">
                {{ lang(`t_agentGroup`) }}</small
              >
            </template>
            <template #list-header>
              <div class="my-50 mx-50">
                <b-button
                  class="w-50"
                  size="sm"
                  variant="flat-secondary"
                  @click="
                    () => {
                      selected_merged_queues = merged_queues;
                      get_live_agent();
                      get_live_queue();
                    }
                  "
                >
                  <feather-icon size="14" icon="CheckCircleIcon" />
                  {{ lang("t_selectAll") }}
                </b-button>
                <b-button
                  class="w-50"
                  size="sm"
                  variant="flat-secondary"
                  @click="
                    () => {
                      selected_merged_queues = [];
                      get_live_agent();
                      get_live_queue();
                    }
                  "
                >
                  <feather-icon size="14" icon="XOctagonIcon" />
                  {{ lang("t_clear") }}
                </b-button>
              </div>
            </template>
          </v-select>
        </b-card>
      </b-col>
    </b-row>

    <!-- Statistics -->
    <b-row class="fade-in-effect" v-if="!full_screen">
      <b-col>
        <b-card>
          <b-card-text>
            <b-row>
              <b-col
                cols="6"
                sm="6"
                md="2"
                @click="get_interaction_details(item.internal_name)"
                v-for="(item, i) in top_bar_info"
                :key="i"
                class="d-flex align-items-center justify-content-start cursor-pointer"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar rounded="lg" size="4rem" :variant="item.color">
                      <!-- <b-icon scale="2rem" icon="telephone-plus"></b-icon> -->
                      <i :class="item.iconClass"></i>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ item.content }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card id="baseDiv" class="mb-0" no-body>
          <!-- <b-card no-body :style="{ height: '85vh', overflow: 'auto' }"> -->
          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-1">
              <b-input-group class="input-group-merge ml-50">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>
              <b-button
                @click="card_mode = !card_mode"
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_changeMode')"
              >
                <i
                  v-if="!card_mode"
                  class="bi bi-person-vcard font-medium-1"
                ></i>
                <i v-else class="bi bi-table font-medium-1"></i>
              </b-button>
              <b-button
                @click="
                  () => {
                    dont_show_go_home_status = !dont_show_go_home_status;
                    get_live_agent();
                  }
                "
                size="sm"
                class="px-50 py-50"
                variant="flat-secondary"
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_showNotConnected')"
              >
                <i
                  class="bi font-medium-1"
                  :class="dont_show_go_home_status ? 'bi-eye' : 'bi-eye-slash'"
                ></i>
              </b-button>
              <b-button
                v-b-modal.modal-announce
                size="sm"
                class="px-50 py-50"
                variant="flat-secondary"
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_announce')"
              >
                <i class="bi bi-megaphone font-medium-1"></i>
              </b-button>
              <b-button
                @click="full_screen = !full_screen"
                size="sm"
                class="px-50 py-50"
                variant="flat-secondary"
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_expand')"
              >
                <b-icon
                  :icon="
                    !full_screen
                      ? 'arrows-angle-expand'
                      : 'arrows-angle-contract'
                  "
                ></b-icon>
              </b-button>
              <b-dropdown
                dropleft
                boundary="viewport"
                size="sm"
                variant="flat-secondary"
                no-caret
                :right="$store.state.appConfig.isRTL"
                toggle-class="px-50 py-50"
              >
                <template #button-content>
                  <i class="bi bi-three-dots-vertical font-medium-1"></i>
                </template>

                <b-dropdown-item v-b-modal.modal-status-change>
                  <span class="align-middle ml-50">{{
                    lang("t_changeAllStatuses")
                  }}</span>
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.modal-queue-change>
                  <span class="align-middle ml-50">{{
                    lang("t_changeAllQueues")
                  }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="ShowFullscreen">
                  <span class="align-middle ml-50">{{
                    lang("t_fullScreen")
                  }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
            <b-table
              class="fade-in-effect"
              v-if="!card_mode"
              :filter="searchQuery"
              small
              hover
              responsive
              @row-dblclicked="showDetails"
              :items="filteredAgents"
              :fields="fields"
            >
              <template #cell(Username)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    class="mr-50"
                    variant="light-secondary"
                    :text="`${data.value
                      .split('.')[0][0]
                      .toUpperCase()}${data.value
                      .split('.')
                      [data.value.split('.').length - 1][0].toUpperCase()}`"
                  >
                  </b-avatar>
                  <span>
                    {{ data.value }}
                  </span>
                </div>
              </template>
              <template #cell(BreakTime)="data">
                <b-button-group>
                  <span> {{ data.value }}</span>
                  <feather-icon
                    v-b-tooltip.hover.v-danger
                    v-b-tooltip.hover.bottom="lang('t_breakTimeFraud')"
                    size="20"
                    v-if="data.item.BreakTimeFraud"
                    class="text-danger ml-1"
                    icon="AlertOctagonIcon"
                  ></feather-icon>
                </b-button-group>
              </template>

              <template #cell(Reason)="data">
                <b-badge
                  :variant="'light-' + GetStatusColorByReason(`${data.value}`)"
                >
                  <div class="d-flex align-items-center">
                    <feather-icon
                      v-if="getReasonIcon(data.value).includes('feather')"
                      size="16"
                      :icon="getReasonIcon(data.value).split('_'[1])"
                    />
                    <span v-if="getReasonIcon(data.value).includes('bi-')">
                      <i
                        class="bi font-medium-1"
                        :class="getReasonIcon(data.value)"
                      ></i>
                    </span>

                    <span class="ml-50">
                      {{ data.value }}
                    </span>
                  </div>
                </b-badge>
              </template>
              <template #cell(RemoteNumber)="data">
                <div class="d-flex align-items-center">
                  <b-icon
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="'inbound'"
                    icon="telephone-inbound"
                    v-if="data.value && data.item.Direction == 'inbound'"
                  ></b-icon>
                  <b-icon
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="'outbound'"
                    icon="telephone-outbound"
                    v-else-if="data.value && data.item.Direction == 'outbound'"
                  ></b-icon>
                  <b-icon
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="'dialer'"
                    icon="telephone-outbound"
                    v-else-if="data.value && data.item.Direction == 'dialer'"
                  ></b-icon>
                  <b-icon
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="'text'"
                    icon="chat-dots"
                    v-else-if="data.value && data.item.Direction == 'text'"
                  ></b-icon>
                  <b-icon
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="'manual'"
                    icon="card-checklist"
                    v-else-if="data.value && data.item.Direction == 'manual'"
                  >
                  </b-icon>
                  <!-- <span v-else> {{ data.item.Direction }}</span> -->
                  <span class="ml-1">{{ data.value }}</span>
                </div>
              </template>
              <template #cell(time)="data">
                {{ timer(data.value) }}
              </template>
              <template #cell(Queue)="data">
                {{
                  data.value == ""
                    ? ""
                    : queues.find((e) => e.internal_name == data.value) !=
                      undefined
                    ? queues
                        .find((e) => e.internal_name == data.value)
                        .display_name.replace(/ /g, "\u00a0") +
                      " / " +
                      (data.item.Classification
                        ? data.item.Classification.replace(/ /g, "\u00a0")
                        : data.item.Classification)
                    : data.value + " / " + data.item.Classification
                }}
              </template>
            </b-table>
            <b-card-text class="mx-2 fade-in-effect" v-else>
              <b-row>
                <b-col
                  sm="12"
                  md="2"
                  v-for="(item, i) in filteredAgents"
                  :key="i"
                >
                  <b-card
                    @dblclick="showDetails(item)"
                    :class="[
                      `bg-light-${GetStatusColorByReason(item.Reason)}`,
                      'cursor-pointer',
                    ]"
                    no-body
                  >
                    <b-card-header>
                      <b-card-title>
                        <b-media no-body>
                          <b-media-aside>
                            <b-avatar
                              :class="[]"
                              :badge="item.Productivity.replace('%', '')"
                              :badge-variant="
                                GetStatusColorByReason(item.Reason)
                              "
                              badge-offset="-0.5em"
                              size="3rem"
                              :variant="
                                'light-' + GetStatusColorByReason(item.Reason)
                              "
                            >
                              {{ getShortName(item.Username) }}
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body
                            class="my-auto"
                            style="min-height: 6.5vh"
                          >
                            <h5
                              class="font-weight-bolder mb-0 d-block text-truncate"
                              style="max-width: 12rem"
                            >
                              {{ usernameToNameSurname(item.Username) }}
                            </h5>
                            <b-badge
                              :variant="
                                'light-' + GetStatusColorByReason(item.Reason)
                              "
                              :class="['ml-auto', 'mb-25']"
                              >{{
                                `${item.Reason}: ${timer(item.time)}`
                              }}</b-badge
                            >
                            <b-card-text class="font-small-3 mb-0">
                              {{ getRemoteNumber(item.RemoteNumber) }}
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </b-card-title>
                    </b-card-header>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="[25, 50, 100]"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />

                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    agents.filter((e) => e.visible_in_reports == true).length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="
                  agents.filter((e) => e.visible_in_reports == true).length
                "
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      size="lg"
      static
      no-close-on-esc
      no-close-on-backdrop
      :title="selected_agent ? selected_agent.Username : ''"
      hide-footer
      centered
      v-model="modal_agent_details"
      dialog-class="dClass75"
    >
      <template #modal-title>
        <h5 class="d-inline-block">{{ lang("t_agent") }}</h5>
        <b-dropdown
          boundary="viewport"
          class="d-inline-block ml-50"
          :variant="'flat-secondary'"
          size="sm"
          :text="selected_agent ? selected_agent.Username : ''"
          toggle-class="px-75"
        >
          <b-dropdown-item
            v-for="(item, i) in agents.filter(
              (e) => e.visible_in_reports == true
            )"
            :key="i"
            @click="showDetails(item)"
          >
            <div class="d-flex align-items-center">
              <b-avatar
                size="2rem"
                :variant="'light-' + GetStatusColorByReason(item.Reason)"
                :text="item.Username[0] + item.Username.split('.')[1][0]"
              ></b-avatar>
              <span class="ml-50"> {{ item.Username }} </span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <div v-if="selected_agent">
        <b-row class="mb-1">
          <b-col sm="12" md="3">
            <b-card body-class="pb-0" style="height: 45rem" class="mb-0">
              <b-card-text
                class="d-flex align-items-center justify-content-center"
              >
                <b-avatar
                  :variant="
                    'light-' + GetStatusColorByReason(selected_agent.Reason)
                  "
                  :badge="
                    selected_agent && selected_agent.details
                      ? selected_agent.details.productivity.toString()
                      : '0'
                  "
                  badge-offset="-0.5em"
                  :badge-variant="GetStatusColorByReason(selected_agent.Reason)"
                  size="3.5rem"
                  :text="selected_agent.name[0] + selected_agent.surname[0]"
                >
                </b-avatar>
              </b-card-text>
              <b-card-text class="text-center">
                <span class="d-block font-medium-3 font-weight-bolder">{{
                  selected_agent.name + " " + selected_agent.surname
                }}</span>
                <span class="text-muted">{{
                  selected_agent.role ? selected_agent.role : "admin"
                }}</span>
              </b-card-text>
              <b-card-text class="text-center">
                <b-dropdown
                  boundary="viewport"
                  :class="'bg-light-' + GetStatusColorByReason(current_reason)"
                  :variant="'flat-' + GetStatusColorByReason(current_reason)"
                  :disabled="
                    ['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                      selected_agent.Status
                    )
                  "
                  size="sm"
                  :text="current_reason"
                >
                  <b-dropdown-item
                    v-for="(item, i) in spv_statuses"
                    :key="i"
                    @click="
                      () => {
                        current_reason = item.display_name;
                        change_user_state();
                        showDetails(
                          agents.find(
                            (e) => e.Username == selected_agent.Username
                          )
                        );
                      }
                    "
                  >
                    {{ item.display_name }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-card-text>
              <b-card-text class="mt-2">
                <v-select
                  :disabled="
                    ['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                      selected_agent.Status
                    )
                  "
                  class="w-100"
                  v-model="filter_values.selectedQueues"
                  :options="all_queues"
                  :reduce="(val) => val.internal_name"
                  label="display_name"
                  :placeholder="lang('t_queue')"
                  multiple
                  @option:selected="add_queue"
                  @option:deselected="remove_queue"
                  deselectFromDropdown
                >
                </v-select>
              </b-card-text>

              <b-card-text class="text-center">
                <div class="text-truncate d-flex align-items-center pb-1">
                  <span class="text-muted text-center pt-1 text-uppercase">
                    {{ "Kullanıcı Bilgileri" }}
                  </span>
                </div>
                <div
                  class="mb-50 text-truncate text-left d-flex align-items-center"
                >
                  <span class="font-weight-bolder">{{
                    lang("t_extension") + ":"
                  }}</span>
                  <span class="ml-50"> {{ selected_agent.exten }} </span>
                </div>
                <div
                  class="mb-50 text-truncate text-left d-flex align-items-center"
                >
                  <span class="font-weight-bolder">{{
                    lang("t_email") + ":"
                  }}</span>
                  <span class="ml-50">
                    {{ selected_agent.email }}
                  </span>
                </div>
                <div
                  class="mb-50 text-truncate text-left d-flex align-items-center"
                >
                  <span class="font-weight-bolder">{{
                    lang("t_project") + ":"
                  }}</span>
                  <span class="ml-50">
                    {{ getProjectsDisplayNames(selected_agent.projects) }}
                  </span>
                </div>

                <div
                  class="mb-50 text-truncate text-left d-flex align-items-center"
                >
                  <span class="font-weight-bolder">{{
                    lang("t_lastLogin") + ":"
                  }}</span>
                  <span class="ml-50">
                    {{ convertDateFormat(selected_agent.LoggedInAt) }}
                  </span>
                </div>
                <div
                  v-if="selected_agent.shift"
                  class="mb-50 text-truncate text-left d-flex align-items-center"
                >
                  <span class="font-weight-bolder">{{
                    lang("t_shift") + ":"
                  }}</span>
                  <span class="ml-50">
                    {{
                      `${selected_agent.shift.shift_start} - ${selected_agent.shift.shift_end}`
                    }}
                  </span>
                </div>
                <div
                  v-if="selected_agent.shift"
                  class="mb-50 text-truncate text-left d-flex align-items-center"
                >
                  <span class="font-weight-bolder">{{
                    lang("t_breakTime") + ":"
                  }}</span>
                  <span class="ml-50">
                    {{
                      selected_agent.shift.break_time + " " + lang("t_minute")
                    }}
                  </span>
                </div>
              </b-card-text>
              <b-card-footer class="position-absolute fixed-bottom border-0">
                <div
                  v-if="getInteractionState"
                  class="d-flex align-items-center justify-content-center mb-1 text-center"
                >
                  <div class="voice-animation text-center">
                    <div
                      v-for="i in 8"
                      :key="i"
                      class="wave rounded-lg"
                      :style="waveStyle(i)"
                    ></div>
                  </div>
                  <b-button
                    @click="closeChanspy"
                    class="ml-auto px-50 py-50"
                    variant="flat-secondary"
                  >
                    <feather-icon icon="XIcon"></feather-icon>
                  </b-button>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <b-button
                    @click="openChanspy('listen')"
                    :disabled="
                      ![
                        'RingingOut',
                        'InInteraction',
                        'AgentRinging ',
                      ].includes(selected_agent.Status)
                    "
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="lang('t_listen')"
                    :class="['btn-icon']"
                    variant="flat-secondary"
                  >
                    <div class="justify-content-center align-items-center">
                      <i class="bi bi-soundwave font-medium-3"></i>
                    </div>
                  </b-button>

                  <b-button
                    @click="openChanspy('souffle')"
                    :disabled="
                      ![
                        'RingingOut',
                        'InInteraction',
                        'AgentRinging ',
                      ].includes(selected_agent.Status)
                    "
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="lang('t_souffle')"
                    :class="['btn-icon']"
                    variant="flat-secondary"
                  >
                    <div class="justify-content-center align-items-center">
                      <i class="bi bi-headset font-medium-3"></i>
                    </div>
                  </b-button>
                  <b-button
                    @click="openChanspy('join')"
                    :disabled="
                      ![
                        'RingingOut',
                        'InInteraction',
                        'AgentRinging ',
                      ].includes(selected_agent.Status)
                    "
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="lang('t_conference')"
                    :class="['btn-icon']"
                    variant="flat-secondary"
                  >
                    <div class="justify-content-center align-items-center">
                      <i class="bi bi-people font-medium-3"></i>
                    </div>
                  </b-button>
                  <b-button
                    v-b-modal.modal-sufle
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="lang('t_writtenSouffle')"
                    :class="['btn-icon']"
                    variant="flat-secondary"
                  >
                    <div class="justify-content-center align-items-center">
                      <i class="bi bi-chat-quote font-medium-3"></i>
                    </div>
                  </b-button>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
          <b-col sm="12" md="9">
            <b-tabs
              lazy
              pills
              active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
            >
              <b-tab active>
                <template #title>
                  <i class="bi bi-clipboard-data font-medium-1"></i>
                  <span>{{ lang("t_statistics") }}</span>
                </template>

                <b-card
                  v-if="selected_agent && selected_agent.details"
                  class="mb-1"
                >
                  <b-card-text>
                    <div
                      class="d-flex align-items-center justify-content-between cursor-pointer"
                    >
                      <div>
                        <b-media no-body>
                          <b-media-aside>
                            <b-avatar
                              rounded="lg"
                              size="3rem"
                              :variant="'light-success'"
                            >
                              <i
                                :class="'bi bi-telephone-plus font-medium-3'"
                              ></i>
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ selected_agent.details.inbound_success }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                              {{ lang("t_answered") }}
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-start cursor-pointer"
                      >
                        <b-media no-body>
                          <b-media-aside>
                            <b-avatar
                              rounded="lg"
                              size="3rem"
                              :variant="'light-danger'"
                            >
                              <!-- <b-icon scale="2rem" icon="telephone-plus"></b-icon> -->
                              <i
                                :class="'bi bi-telephone-minus font-medium-3'"
                              ></i>
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ selected_agent.details.inbound_failed }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                              {{ lang("t_abandoned") }}
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-start cursor-pointer"
                      >
                        <b-media no-body>
                          <b-media-aside>
                            <b-avatar
                              rounded="lg"
                              size="3rem"
                              :variant="'light-success'"
                            >
                              <!-- <b-icon scale="2rem" icon="telephone-plus"></b-icon> -->
                              <i
                                :class="'bi bi-person-check font-medium-3'"
                              ></i>
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ selected_agent.details.outbound_success }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                              {{ lang("t_successfullCall") }}
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </div>
                      <div
                        cols="6"
                        sm="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start cursor-pointer"
                      >
                        <b-media no-body>
                          <b-media-aside>
                            <b-avatar
                              rounded="lg"
                              size="3rem"
                              :variant="'light-danger'"
                            >
                              <!-- <b-icon scale="2rem" icon="telephone-plus"></b-icon> -->
                              <i :class="'bi bi-person-x font-medium-3'"></i>
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ selected_agent.details.outbound_failed }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                              {{ lang("t_unsuccessfullCall") }}
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </div>
                    </div>
                  </b-card-text>
                </b-card>

                <b-row>
                  <b-col md="7" sm="12">
                    <b-card
                      style="height: 34rem; overflow-y: auto"
                      no-body
                      class="mb-0"
                    >
                      <b-table
                        v-if="selected_agent.hasOwnProperty('details')"
                        small
                        :fields="fc_fields"
                        :items="selected_agent.details.fc_data"
                        responsive="sm"
                        class="mb-0"
                      >
                        <!-- A custom formatted column -->
                        <template #cell(rate)="data">
                          <center>
                            <b-progress
                              show-value
                              :value="
                                (data.value * 100) /
                                selected_agent.details.total_finish_code_counts
                              "
                              :max="100"
                              :variant="
                                parseInt(
                                  (data.value /
                                    selected_agent.details
                                      .total_finish_code_counts) *
                                    100
                                ) > 40
                                  ? 'success'
                                  : 'warning'
                              "
                            />
                          </center>
                        </template>

                        <template #cell(category)="data">
                          <center>
                            <b-badge variant="light-secondary">
                              {{ data.value }}
                            </b-badge>
                          </center>
                        </template>
                      </b-table>
                    </b-card>
                  </b-col>
                  <b-col md="5" sm="12">
                    <b-card
                      class="card-transaction mb-0"
                      style="height: 34rem; overflow: auto"
                      no-body
                    >
                      <b-card-header>
                        <b-card-title>{{
                          selected_type.display_name
                        }}</b-card-title>

                        <b-dropdown
                          variant="link"
                          no-caret
                          toggle-class="py-50 px-50"
                          right
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="18"
                              class="text-body cursor-pointer"
                            />
                          </template>
                          <b-dropdown-item
                            @click="selected_type = item"
                            v-for="item in types.filter(
                              (e) =>
                                e.internal_name != selected_type.internal_name
                            )"
                            :key="item.internal_name"
                            href="#"
                          >
                            {{ item.display_name }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-card-header>

                      <b-card-body
                        v-if="selected_type.internal_name == 'statuses'"
                      >
                        <div
                          v-for="(item, i) in st_data"
                          class="transaction-item"
                          :key="i"
                        >
                          <b-media no-body>
                            <b-media-aside>
                              <b-avatar
                                rounded
                                size="42"
                                :variant="
                                  colors.hasOwnProperty(item.display_name)
                                    ? `light-${colors[item.display_name]}`
                                    : 'light-primary'
                                "
                              >
                                <feather-icon
                                  v-if="
                                    getReasonIcon(item.display_name).includes(
                                      'feather'
                                    )
                                  "
                                  size="18"
                                  :icon="
                                    getReasonIcon(item.display_name).split(
                                      '_'[1]
                                    )
                                  "
                                />
                                <i
                                  v-if="
                                    getReasonIcon(item.display_name).includes(
                                      'bi-'
                                    )
                                  "
                                  class="bi font-medium-3"
                                  :class="getReasonIcon(item.display_name)"
                                ></i>
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body>
                              <span class="d-block font-small-4">
                                {{ item.display_name }}
                              </span>
                              <b-badge variant="light-secondary"></b-badge>
                              <small class="text-muted">{{
                                item.category
                              }}</small>
                            </b-media-body>
                          </b-media>
                          <div
                            class="font-weight-bolder font-medium-1"
                            :class="true ? 'text-secondary' : 'text-secondary'"
                          >
                            {{ toHHMMSS(item.time) }}
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body v-else>
                        <div
                          v-for="(item, i) in Object.keys(performance_info)"
                          class="transaction-item"
                          :key="i"
                        >
                          <b-media no-body>
                            <b-media-aside>
                              <b-avatar
                                rounded
                                size="42"
                                :variant="performance_info[item].variant"
                              >
                                <!-- <feather-icon size="18" :icon="'UserIcon'" /> -->
                                <i
                                  class="bi font-medium-3"
                                  :class="performance_info[item].icon"
                                ></i>
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body>
                              <span class="d-block font-small-4">
                                {{ lang(`t_${item}`) }}
                              </span>
                              <b-badge variant="light-secondary"></b-badge>
                              <small class="text-muted">{{
                                lang(`t_${item}Descrition`)
                              }}</small>
                            </b-media-body>
                          </b-media>
                          <div
                            class="font-weight-bolder text-secondary font-medium-1"
                          >
                            {{ performance_info[item].value }}
                          </div>
                        </div>
                      </b-card-body>
                    </b-card>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <template #title>
                  <i class="bi bi-chat font-medium-1"></i>
                  <span>{{ lang("t_externalChat") }}</span>
                </template>
                <chat></chat>
              </b-tab>
              <b-tab disabled>
                <template #title>
                  <i class="bi bi-clipboard-data font-medium-1"></i>
                  <span>{{ lang("t_quality") }}</span>
                </template>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </div>
      <b-tabs v-if="false">
        <b-tab title="Özet">
          <b-row
            v-if="selected_agent.hasOwnProperty('details')"
            class="border-bottom py-1"
          >
            <b-col class="border-right">
              <center>
                <b class="text-success">{{ lang("t_answered") }}</b>
              </center>
              <center class="text-success">
                {{ selected_agent.details.inbound_success }}
              </center>
            </b-col>
            <b-col class="border-right">
              <center>
                <b class="text-danger">{{ lang("t_abandonedCount") }}</b>
              </center>
              <center class="text-danger">
                {{ selected_agent.details.inbound_failed }}
              </center>
            </b-col>
            <b-col class="border-right">
              <center>
                <b class="text-primary">{{ lang("t_productivity") }}</b>
              </center>
              <center class="text-primary">
                %{{ selected_agent.details.productivity }}
              </center>
            </b-col>
            <b-col class="border-right">
              <center>
                <b class="text-success">{{ lang("t_successfullCall") }}</b>
              </center>
              <center class="text-success">
                {{ selected_agent.details.outbound_success }}
              </center>
            </b-col>
            <b-col>
              <center>
                <b class="text-danger">{{ lang("t_unsuccessfullCall") }}</b>
              </center>
              <center class="text-danger">
                {{ selected_agent.details.outbound_failed }}
              </center>
            </b-col>
          </b-row>
          <b-row
            v-if="selected_agent.hasOwnProperty('details')"
            class="border-bottom py-1"
          >
            <b-col class="border-right">
              <center>
                <b>{{ lang("t_usedBreakTime") }}</b>
              </center>
              <center>{{ selected_agent.details.break_time }}</center>
            </b-col>
            <b-col
              v-if="selected_agent.details.break_time_fraud"
              class="border-right"
            >
              <center>
                <b class="text-danger">{{ lang("t_reakTimeFraud") }}</b>
                <center>
                  <feather-icon
                    class="text-danger"
                    icon="AlertOctagonIcon"
                  ></feather-icon>
                </center>
              </center>
            </b-col>
            <b-col>
              <center>
                <b>{{ lang("t_remainingBreakTime") }}</b>
              </center>
              <center>{{ selected_agent.details.remaining_break_time }}</center>
            </b-col>
          </b-row>
          <b-row v-if="selected_agent.hasOwnProperty('details')" class="py-1">
            <b-table
              style="max-height: 26vh; overflow-y: auto"
              small
              :fields="fc_fields"
              :items="selected_agent.details.fc_data"
              responsive="sm"
              fixed
            >
              <!-- A custom formatted column -->
              <template #cell(rate)="data">
                <center>
                  <b-progress
                    show-value
                    :value="data.value"
                    :max="selected_agent.details.total_finish_code_counts"
                    :variant="
                      parseInt(
                        (data.value /
                          selected_agent.details.total_finish_code_counts) *
                          100
                      ) > 40
                        ? 'success'
                        : 'warning'
                    "
                    striped
                  />
                </center>
              </template>

              <template #cell(category)="data">
                <center>
                  <b-badge
                    style="width: 70%"
                    pill
                    :variant="fc_category_colors[data.value]"
                  >
                    {{ data.value }}
                  </b-badge>
                </center>
              </template>
            </b-table>
          </b-row>
          <b-row class="mt-1">
            <v-select
              :disabled="
                ['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                  selected_agent.Status
                )
              "
              class="w-100"
              v-model="filter_values.selectedQueues"
              :options="all_queues"
              :reduce="(val) => val.internal_name"
              label="display_name"
              :placeholder="lang('t_queue')"
              multiple
              @option:selected="add_queue"
              @option:deselected="remove_queue"
            >
            </v-select>
          </b-row>
          <b-row class="mt-1">
            <v-select
              :disabled="
                ['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                  selected_agent.Status
                )
              "
              class="w-100"
              v-model="current_reason"
              :options="spv_statuses"
              label="display_name"
              :reduce="(val) => val.display_name"
              :placeholder="lang('t_status')"
              @input="change_user_state"
              :clearable="false"
              :closeOnSelect="false"
            >
            </v-select>
          </b-row>
          <b-row class="mt-1" align-h="center">
            <b-col cols="8" class="text-center">
              <b-button
                :disabled="
                  !['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                    selected_agent.Status
                  )
                "
                @click="chanspy('listen')"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_listen')"
                variant="outline-primary"
                class="btn-icon rounded-circle mx-1"
              >
                <feather-icon icon="HeadphonesIcon" />
              </b-button>

              <b-button
                :disabled="
                  !['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                    selected_agent.Status
                  )
                "
                @click="chanspy('souffle')"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_souffle')"
                variant="outline-primary"
                class="btn-icon rounded-circle mx-1"
              >
                <feather-icon icon="BellIcon" />
              </b-button>

              <b-button
                :disabled="
                  !['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                    selected_agent.Status
                  )
                "
                @click="chanspy('join')"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_conference')"
                variant="outline-primary"
                class="btn-icon rounded-circle mx-1"
              >
                <feather-icon icon="UsersIcon" />
              </b-button>
            </b-col>
            <b-col class="border-left text-center">
              <b-button
                v-b-modal.modal-sufle
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_writtenSouffle')"
                variant="outline-primary"
                class="btn-icon rounded-circle mx-1"
              >
                <feather-icon icon="MessageSquareIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Chat">
          <chat></chat>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal
      static
      dialog-class="dClass"
      no-close-on-esc
      no-close-on-backdrop
      :title="
        selected_interaction_type == 'abandoned_interactions'
          ? lang('t_mrAbandonedCalls')
          : lang('t_waitingInQueue')
      "
      hide-footer
      centered
      v-model="modal_interaction_details"
      @hide="set_abandon_call_fields"
      @close="set_abandon_call_fields"
    >
      <b-table
        :busy="isBusy"
        :items="interaction_details_data"
        :fields="interaction_details_fields"
        striped
        responsive="sm"
        style="max-height: 60vh; overflow-y: auto"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(is_reached)="data">
          <feather-icon
            size="20"
            :icon="data.value ? 'CheckCircleIcon' : 'XCircleIcon'"
          />
        </template>
        <template #cell(queue)="data">
          <b-badge variant="light-primary">
            {{
              queues.find((e) => e.internal_name == data.value)
                ? queues.find((e) => e.internal_name == data.value).display_name
                : data.value
            }}
          </b-badge>
        </template>
        <template #cell(time)="data">
          {{ timer(data.value) }}
        </template>
      </b-table>
      <b-button
        v-if="selected_interaction_type == 'abandoned_interactions'"
        @click="onexport"
        class="float-right"
        variant="success"
        >{{ lang("t_export") }}
      </b-button>
      <b-button
        v-if="selected_interaction_type == 'abandoned_interactions'"
        @click="abandon_call_details"
        class="float-right mr-1"
        variant="primary"
        >{{ lang("t_showCalledBack") }}
      </b-button>
    </b-modal>
    <b-modal
      static
      id="modal-sufle"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_send')"
      centered
      ok-only
      :title="lang('t_writtenSouffle')"
      @ok="sendSouffle"
    >
      <b-form-group>
        <label for="message">{{ lang("t_text") }}</label>
        <b-form-input
          v-model="souffle_message"
          id="message"
          :placeholder="lang('t_yourMessage')"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      v-if="selected_agent"
      static
      v-model="modal_chanspy"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_close')"
      centered
      ok-only
      :title="selected_agent.username"
      @close="closeChanspy"
      @hidden="closeChanspy"
      @ok="closeChanspy"
    >
      <div class="d-flex align-items-center justify-content-center">
        <div class="voice-animation">
          <div
            v-for="i in 8"
            :key="i"
            class="wave rounded-lg"
            :style="waveStyle(i)"
          ></div>
        </div>
      </div>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      id="modal-announce"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_send')"
      centered
      ok-only
      :title="lang('t_announcement')"
      @ok="sendAnnouncement"
    >
      <label for="message"> {{ lang("t_text") }}</label>
      <b-form-input
        v-model="announcement_message"
        id="message"
        :placeholder="lang('t_yourMessage')"
      />
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      id="modal-status-change"
      cancel-variant="secondary"
      :ok-title="lang('t_confirm')"
      centered
      :title="lang('t_changeAllStatuses')"
      @ok="change_status_selected_users"
      :cancel-title="lang('t_cancel')"
    >
      <b-form-group>
        <label for="message">{{ lang("t_agent") }}</label>
        <v-select
          v-model="status_change_info.selected_users"
          class="mt-50"
          :reduce="(option) => option.Username"
          label="Username"
          :options="
            agents.filter(
              (e) =>
                !['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                  e.Status
                )
            )
          "
          :placeholder="lang('t_agent')"
          multiple
          :closeOnSelect="false"
        >
          <template #list-header>
            <div class="my-50 mx-50">
              <b-button
                class="w-50"
                size="sm"
                variant="flat-secondary"
                @click="
                  () => {
                    status_change_info.selected_users = agents
                      .filter(
                        (e) =>
                          ![
                            'RingingOut',
                            'InInteraction',
                            'AgentRinging ',
                          ].includes(e.Status)
                      )
                      .map((e) => e.Username);
                  }
                "
              >
                <feather-icon size="14" icon="CheckCircleIcon" />
                {{ lang("t_selectAll") }}
              </b-button>
              <b-button
                class="w-50"
                size="sm"
                variant="flat-secondary"
                @click="
                  () => {
                    status_change_info.selected_users = [];
                  }
                "
              >
                <feather-icon size="14" icon="XOctagonIcon" />
                {{ lang("t_clear") }}
              </b-button>
            </div>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <label for="message">{{ lang("t_status") }}</label>
        <v-select
          v-model="status_change_info.status"
          :options="spv_statuses"
          :reduce="(val) => val.display_name"
          :placeholder="lang('t_status')"
          label="display_name"
        >
        </v-select>
      </b-form-group>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      id="modal-queue-change"
      cancel-variant="danger"
      :ok-title="lang('t_add')"
      :cancel-title="lang('t_remove')"
      centered
      :title="lang('t_changeAllQueues')"
      @ok="add_queues_selected_users"
      @cancel="remove_queues_selected_users"
    >
      <b-form-group>
        <label for="message">{{ lang("t_agent") }}</label>
        <v-select
          v-model="queue_change_info.selected_users"
          class="mt-50"
          :reduce="(option) => option.Username"
          label="Username"
          :options="
            agents.filter(
              (e) =>
                !['RingingOut', 'InInteraction', 'AgentRinging '].includes(
                  e.Status
                )
            )
          "
          :placeholder="lang('t_agent')"
          multiple
          :closeOnSelect="false"
        >
          <template #list-header>
            <div class="my-50 mx-50">
              <b-button
                class="w-50"
                size="sm"
                variant="flat-secondary"
                @click="
                  () => {
                    queue_change_info.selected_users = agents
                      .filter(
                        (e) =>
                          ![
                            'RingingOut',
                            'InInteraction',
                            'AgentRinging ',
                          ].includes(e.Status)
                      )
                      .map((e) => e.Username);
                  }
                "
              >
                <feather-icon size="14" icon="CheckCircleIcon" />
                {{ lang("t_selectAll") }}
              </b-button>
              <b-button
                class="w-50"
                size="sm"
                variant="flat-secondary"
                @click="
                  () => {
                    queue_change_info.selected_users = [];
                  }
                "
              >
                <feather-icon size="14" icon="XOctagonIcon" />
                {{ lang("t_clear") }}
              </b-button>
            </div>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <label for="message">{{ lang("t_queue") }}</label>
        <v-select
          multiple
          v-model="queue_change_info.queues"
          :options="queues"
          :reduce="(val) => val.internal_name"
          :placeholder="lang('t_queue')"
          label="display_name"
        >
        </v-select>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BProgress,
  BProgressBar,
  BSpinner,
  BForm,
  BButtonGroup,
  BFormInput,
  BContainer,
  VBTooltip,
  BModal,
  BTable,
  BButton,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormCheckbox,
  BBadge,
  BCardBody,
  BTabs, BTab, BInputGroupPrepend, BInputGroup, BPagination, BFormSelect, BCardFooter, BDropdown, BDropdownItem
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { VueGoodTable } from "vue-good-table";
import Chat from "./Components/Chat.vue";

function safeDivision(denominator) {
  return denominator === 0 ? 1 : denominator;
}


export default {
  components: {
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardFooter,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    Chat,
    BProgress,
    BProgressBar,
    BSpinner,
    BForm,
    BButtonGroup,
    BFormInput,
    BContainer,
    VueGoodTable,
    BCardActions,
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    vSelect,
    BFormGroup,
    VueApexCharts,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BFormCheckbox,
    BBadge,
    BCardBody,
    BCardCode,
    BTable,
    StatisticCardHorizontal,
    BModal,
    BTabs, BTab, BDropdown, BDropdownItem
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    getInteractionState() {
      if (![undefined, null].includes(globalThis.LayoutContentRendererDefault.interaction[1])) {
        return true;
      } else {
        return false;
      }

    },
    filteredAgents() {
      let list = [];
      for (const item of this.agents) {
        if (this.agent_group_list.length > 0) {
          if (this.agent_group_list.find((j) => j == item.Identifier && item.visible_in_reports == true)) {
            list.push(item);
          }

        }
        else {
          if (item.visible_in_reports == true) {
            list.push(item);
          }
        }

      }
      return list;
    }
  },
  watch: {
    selected_merged_queues(newValue, oldValue) {
      console.log("newValue", newValue);
      this.selected_queues = [];
      this.agent_group_list = [];
      let q_set = new Set();
      for (const row of newValue) {
        if (row._type == 'queue_group') {
          for (const _queue of row.queues) {
            q_set.add(_queue.internal_name);
          }
        }
        if (row._type == 'agent_group') {
          for (const _agent of row.agents) {
            this.agent_group_list.push(_agent);
          }
        }
        else {
          q_set.add(row.internal_name);
        }
      }
      this.selected_queues = Array.from(q_set);
      console.log("selected_queues", this.selected_queues);
      this.get_live_agent();
      this.get_live_queue();
    }
  },
  data: () => ({
    lang: _l(window.localStorage.getItem("language") || "tr"),
    souffle_message: "",
    is_progress: false,
    currentPage: 1,
    perPage: 100,
    card_mode: false,
    modal_chanspy: false,
    announcement_message: "",
    filter_values: {
      selectedQueues: [],
      selectedQueues2: [],
    },
    status_change_info: {
      selected_users: [],
      status: "",
    },
    queue_change_info: {
      selected_users: [],
      queues: [],
    },
    searchQuery: "",
    selected_agent: null,
    selected_merged_queues: [],
    merged_queues: [],
    agent_group_list: [],
    queues: [],
    shifts: [],
    dont_show_go_home_status: true,
    queue_groups: [],
    selected_queue_group: [],
    revenue: {},
    data_timer: {},
    chart_timer: {},
    modal_agent_details: false,
    modal_interaction_details: false,
    st_data: [],
    fc_fields: [
      {
        key: "finish_code",
        label: globalThis._lang("t_finishCode"),
        sortable: true,
      },
      { key: "count", label: globalThis._lang("t_count"), sortable: true },
      { key: "rate", label: globalThis._lang("t_rate"), sortable: true },
      {
        key: "category",
        label: globalThis._lang("t_category"),
        sortable: true,
      },
    ],
    fc_category_colors: {
      Başarılı: "success",
      Başarısız: "warning",
      Randevu: "primary",
      Diğer: "secondary",
    },
    revenueComparisonLine: {
      series: [
        // {
        //   type: 'area',
        //   name: 'Gelen',
        //   data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        // },
        {
          type: "area",
          name: globalThis._lang("t_abandonedCount"),
          data: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ],
        },
      ],
      chartOptions: {
        chart: {
          animations: {
            enabled: false,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: false,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: false,
              speed: 350,
            },
          },
          toolbar: { show: false }, // PDF PNG vb. uzantılarda indirmeni sağlıyor.
          zoom: { enabled: false },
          type: "line",
          offsetX: -10,
        },
        stroke: {
          curve: "smooth",
          // dashArray: [0, 12, 0],
          width: [4, 3, 4],
        },
        legend: {
          show: true,
          labels: {
            colors: [$themeColors.secondary],
            useSeriesColors: false,
          },
        },

        colors: [
          $themeColors.danger,
          $themeColors.primary,
          $themeColors.primary,
        ],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: ["#ebe9f1"],
            shadeIntensity: 1,
            type: "vertical",
            opacityFrom: 0.75,
            opacityTo: 0.5,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            show: true, // Altta gözüken yazıları kapatıyor --   categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
            style: {
              colors: "#b9b9c3",
              fontSize: "1rem",
            },
          },
          axisTicks: {
            show: false,
          },
          categories: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
          ],
          axisBorder: {
            show: false,
          },
          tickPlacement: "on",
        },
        yaxis: {
          ticks: {
            stepSize: 5,
            min: 0,
            fontColor: "#6e6b7b",
          },
          // tickAmount: 1,
          labels: {
            style: {
              colors: "#b9b9c3",
              fontSize: "1rem",
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(0)}k` : val;
            },
          },
        },
        grid: {
          borderColor: "#e7eef7",
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
    },
    selected_type: { internal_name: 'performance', display_name: globalThis._lang('t_performance') },
    types: [
      { internal_name: 'statuses', display_name: globalThis._lang('t_statuses') },
      { internal_name: 'performance', display_name: globalThis._lang('t_performance') }
    ],
    performance_info: {
      totalCallTime: { icon: 'bi-patch-plus', variant: 'light-success', value: 0 },
      avgCallTime: { icon: 'bi-graph-up', variant: 'light-primary', value: 0 },
      avgAnswerTime: { icon: 'bi-speedometer', variant: 'light-danger', value: 0 },
      avgHoldTime: { icon: 'bi-pause-circle', variant: 'light-warning', value: 0 },
      avgAcwTime: { icon: 'bi-clipboard-pulse', variant: 'light-info', value: 0 },
    },
    colors: {
      "Available": "success",
      "Lunch": "warning",
      "Break": "warning",
      "In Call - Outbound": "info",
      "In Call - Inbound": "info",
      "In Call - Dialer": "info",
      "In Call - Transfer": "info",
      "In Call - Internal": "info",
      "Second Line": "info",
      "In Text": "info",
      "Awaiting Transfer": "info",
      "ACW": "secondary",
      "Calling": "info",
      "Ringing": "info",
      "Paid Leave": "primary",
      "Unpaid Leave": "primary",
      "Not Connected": "primary",
    },
    reason_icons: {
      "Available": "bi-wifi",
      "Break": "bi-cup-hot",
      "Second Line": "bi-2-square",
      "In Call - Outbound": "bi-telephone-outbound",
      "In Call - Inbound": "bi-telephone-inbound",
      "In Call - Dialer": "bi-telephone-inbound",
      "In Call - Transfer": "bi-telephone-forward",
      "In Call - Internal": "bi-phone-flip",
      "In Text": "feather_MessageCircleIcon",
      "Awaiting Transfer": "bi-hourglass-split",
      "ACW": "bi-window-stack",
      "Calling": "bi-telephone",
      "Ringing": "bi-phone-vibrate",
      "Offline": "bi-wifi-off",
      "Paid Leave": "bi-person-check",
      "Unpaid Leave": "bi-person-dash",
      "Not Connected": "bi-person-slash",
    },
    // { key: 'avatar', label: '' }
    fields: [
      { key: "Username", label: globalThis._lang("t_agent"), sortable: true },
      {
        key: "RemoteNumber",
        label: globalThis._lang("t_number"),
        sortable: true,
      },
      { key: "Queue", label: globalThis._lang("t_queue"), sortable: true },
      { key: "Reason", label: globalThis._lang("t_status"), sortable: true },
      { key: "time", label: globalThis._lang("t_duration"), sortable: true, thStyle: { width: '15vh' } },
      {
        key: "Productivity",
        label: globalThis._lang("t_productivity"),
        sortable: true,
        thStyle: { width: '15vh' }
      },
      {
        key: "BreakTime",
        label: globalThis._lang("t_usedBreakTime"),
        sortable: true,
        thStyle: { width: '15vh' }
      },
    ],
    items: [
      {
        id: 1,
        avatar: require("@/assets/images/avatars/10-small.png"),
        Temsilci: "Ahmet İnanır",
        Dahili: "1011",
        Süre: "00:27:27",
        status: 1,
      },
    ],
    status: [
      {
        1: "Available",
        2: "In Call",
        3: "Break",
        4: "Offline",
        5: "Applied",
      },
      {
        1: "light-success",
        2: "light-warning",
        3: "light-primary",
        4: "light-danger",
      },
    ],
    agents: [],
    status_type: {},
    spv_statuses: [],
    status_categories: [],
    all_queues: [],
    queues: [],
    selected_queues: [],
    projects: [],
    visible_users: [],
    calls: [],
    hide_ofline_users: false,
    calls_info: {
      waiting: 0,
      abandoned: 0,
      total: 0,
    },
    top_bar_info: [
      {
        internal_name: "total_agent",
        subtitle: globalThis._lang("t_totalAgents"),
        content: 0,
        label: "",
        iconClass: 'bi bi-people font-medium-5',
        customClass: "",
        icon: "UserIcon",
        color: "light-primary",
      },
      {
        internal_name: "incall_agent",
        subtitle: globalThis._lang("t_inCall"),
        content: 0,
        label: "",
        iconClass: ['bi', 'bi-headset', 'font-medium-5'],
        customClass: "",
        icon: "PhoneCallIcon",
        color: "light-info",
      },
      {
        internal_name: "waiting_interactions",
        subtitle: globalThis._lang("t_callsInQueue"),
        content: 0,
        label: "",
        iconClass: ['bi', 'bi-clock-history', 'font-medium-5'],
        customClass: "cursor-pointer",
        icon: "WatchIcon",
        color: "light-secondary",
      },
      {
        internal_name: "available_agent",
        subtitle: globalThis._lang("t_available"),
        content: 0,
        label: "",
        iconClass: ['bi', 'bi-person', 'font-medium-5'],
        customClass: "",
        icon: "UserCheckIcon",
        color: "light-success",
      },

      {
        internal_name: "paused_agent",
        subtitle: globalThis._lang("t_paused"),
        content: 0,
        label: "",
        iconClass: ['bi', 'bi-cup-hot', 'font-medium-5'],
        customClass: "",
        icon: "UserXIcon",
        color: "light-warning",
      },

      {
        internal_name: "abandoned_interactions",
        subtitle: globalThis._lang("t_abandonedCount"),
        content: 0,
        label: "",
        iconClass: ['bi', 'bi-telephone-x', 'font-medium-5'],
        customClass: "cursor-pointer",
        icon: "PhoneMissedIcon",
        color: "light-danger",
      },
    ],
    interaction_details_fields: [
      { label: globalThis._lang("t_date"), key: "date", sortable: true },
      { label: globalThis._lang("t_number"), key: "phone", sortable: true },
      { label: globalThis._lang("t_queue"), key: "queue", sortable: true },
      { label: globalThis._lang("t_waitTime"), key: "time", sortable: true },
      // { label: "Arandı Mı?", key: 'is_reached',sortable:true },
    ],
    interaction_details_data: [],
    chart_data_info: {
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    live_interval: {},
    timeInterval: [],
    wait_time_interval: {},
    selected_interaction_type: "",
    current_reason: "",
    full_screen: false,
    isBusy: false,
    conversations: [],
    closed_conversations: [],
    users: [],
  }),
  methods: {

    ShowFullscreen() {
      var elem = document.getElementById("baseDiv");

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    getReasonIcon(reason) {
      if (this.reason_icons.hasOwnProperty(reason)) {
        return this.reason_icons[reason];
      }

      return 'bi-clock-history';
    },
    waveStyle(index) {
      const delay = Math.random() * 0.5; // Random delay between 0 and 0.5 seconds
      const height = Math.random() * (1.5 - 0.5) + 0.5; // Random scale factor between 0.5 and 1.5
      return {
        animationDelay: `${delay}s`,
        animationDuration: `${0.5 + delay}s`,
        transform: `scaleY(${height})`
      };
    },
    openChanspy(type) {
      // this.modal_chanspy = true;
      this.chanspy(type);
    },
    closeChanspy() {
      this.chanspy('close');
    },
    convertDateFormat(timestamp) {
      const dateTime = new Date(timestamp);

      if (isNaN(dateTime.getTime())) {
        return 'Invalid date';
      }

      const day = ('0' + dateTime.getDate()).slice(-2);
      const month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
      const year = dateTime.getFullYear();
      const hours = ('0' + dateTime.getHours()).slice(-2);
      const minutes = ('0' + dateTime.getMinutes()).slice(-2);
      const seconds = ('0' + dateTime.getSeconds()).slice(-2);

      const newFormat = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      return newFormat;
    },
    GetStatusColorByReason(reason) {
      const val = globalThis.statuses.find(e => e.display_name == reason);
      console.log("val", val);
      if (val) {
        let _category = globalThis.status_categories.find(e => e._id == val.status_category_id);
        console.log("_category", _category);

        if (_category) {
          switch (_category.display_name) {
            case 'Available':
              return 'success';
            case 'Offline':
              return 'danger';
            case 'Break':
              return 'warning';
            case 'In Call':
              return 'info';
            default:
              return 'primary';
          }
        }
      } else {
        return 'primary';
      }



    },
    onCheckAll() {
      if (this.merged_queues.length != this.selected_merged_queues.length) {
        this.selected_merged_queues = this.merged_queues
      } else {
        this.selected_merged_queues = [];
      }
    },
    getRemoteNumber(number) {
      return number;
      console.log(number);
      return number.trim().length == 0 ? '​-' : number;

    },
    usernameToNameSurname(username) {
      let _username = username.split('.');
      for (let item of _username) {
        item = item.toUpperCase();
      }
      return _username.join(' ');

    },
    getShortName(username) {
      let _username = username.split('.');
      let _shortname = _username[0].toUpperCase()[0] + _username[_username.length - 1].toUpperCase()[0];
      return _shortname;
    },


    getStatusDetails(row) {
      if (["RingingOut", "InInteraction", "AgentRinging"].includes(row.Status) && row.CurrentInteraction) {
        if (row.CurrentInteraction.InteractionType != 'Text') {
          if (row.CurrentInteraction.Extra.Direction == "outbound") {
            return row.CurrentInteraction.Extra.Classification;
          } else if (row.CurrentInteraction.Extra.Direction == "inbound") {
            return row.CurrentInteraction.Extra.CallerIDName;
          } else {
            return row.CurrentInteraction.Extra.LocalNumber;
          }
        } else {
          return row.CurrentInteraction.Extra.CallerIDName;
        }
      } else {
        return "";
      }
    },
    toHHMMSS(sec_num) {
      try {
        // if (isNaN(sec_num)) {
        //   return "00:00:00";
        // }
        return new Date(sec_num * 1000).toISOString().substr(11, 8);
      } catch (error) {
        return sec_num;
      }
    },
    set_abandon_call_fields() {
      this.interaction_details_fields = [
        { label: globalThis._lang("t_date"), key: "date", sortable: true },
        { label: globalThis._lang("t_number"), key: "phone", sortable: true },
        { label: globalThis._lang("t_queue"), key: "queue", sortable: true },
        { label: globalThis._lang("t_waitTime"), key: "time", sortable: true },
        // { label: "Arandı Mı?", key: 'is_reached',sortable:true },
      ];
    },
    onexport() {
      // On Click Excel download button
      let _headers = {};
      for (const header of this.interaction_details_fields) {
        _headers[header.key] = header.label;
      }

      let _data = [];
      for (const row of this.interaction_details_data) {
        let obj = {};
        delete row._rowVariant;

        for (const column of Object.keys(row)) {
          let key = _headers[column] == undefined ? column : _headers[column];
          obj[key] = row[column];
        }
        _data.push(obj);
      }

      var data = this.$xlsx.utils.json_to_sheet(_data);

      // A workbook is the name given to an Excel file
      var wb = this.$xlsx.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      this.$xlsx.utils.book_append_sheet(wb, data, `AbandonedList`); // sheetAName is name of Worksheet

      // export Excel file
      this.$xlsx.writeFile(wb, `AbandonedList_${new Date().getTime()}.xlsx`); // name of the file is 'book.xlsx'
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },

    async sendAnnouncement() {
      if (this.announcement_message == "") {
        return;
      } else {
        globalThis._voyce.$qm.emit("Chat:AnnouncementMessage", {
          message: this.announcement_message,
          project: globalThis.user.selected_project,
        });
        this.announcement_message = "";
      }
    },
    async sendSouffle() {
      if (this.souffle_message == "") {
        return;
      } else {
        console.log("this.selected_agent.Username", this.selected_agent);
        globalThis._voyce.$qm.emit("Chat:SouffleMessage", {
          target: `${this.selected_agent.Username}@${this.selected_agent.Domain}`,
          message: this.souffle_message,
        });

        this.souffle_message = "";
      }
    },
    call(unique_id, type) {
      globalThis.vSip.RegisterHandlers({
        progress: function (e) {
          // console.log('call is in progressaaaa OUTBOUND');
        },
        failed: function (e) {
          // console.log('call failed with cause3:  OUTBOUND', e);
        },
        ended: function (e) {
          // console.log('ended SPV', e);
          globalThis.LayoutContentRendererDefault.interaction[
            globalThis.LayoutContentRendererDefault.selected_line
          ] = null;
        },
        confirmed: function (e) {
          // console.log('call confirmed OUTBOUND');
        },
        peerconnection: function (e) {
          // console.log('call peerconnection OUTBOUND');
        },
        connecting: function (e) { },
        accepted: function (e) {
          // console.log('call accepted OUTBOUND');
        },
      });

      if (
        globalThis.LayoutContentRendererDefault.interaction[
        globalThis.LayoutContentRendererDefault.selected_line
        ] == null
      ) {
        console.log("GİRDİ", unique_id, type);

        globalThis.LayoutContentRendererDefault.interaction[
          globalThis.LayoutContentRendererDefault.selected_line
        ] = globalThis.vSip.Call(`chanspy_${unique_id}`, [
          `X-Domain: ${globalThis.user.domain}`,
          `X-Type: ${type}`,
        ]);
      }
    },
    async chanspy(type) {
      if (globalThis.LayoutContentRendererDefault.interaction[1] != null) {
        globalThis.LayoutContentRendererDefault.interaction[1].terminate();
        globalThis.LayoutContentRendererDefault.interaction[1] = null;
        return;
      }

      await this.get_live_agent();
      let _user = this.agents.find(
        (e) => e.Username == this.selected_agent.Username
      );

      let unique_id = _user.CurrentInteraction.InteractionID;

      if (type == "join") {
        this.call(unique_id, type);
      }
      if (type == "souffle") {
        this.call(unique_id, type);
      }
      if (type == "listen") {
        this.call(unique_id, type);
      }
    },
    change_status_selected_users() {
      for (const agent of this.status_change_info.selected_users) {
        if (this.status_change_info.status != "Available") {
          globalThis._voyce.$qm.emit("qmgr:SPVPause", {
            reason: this.status_change_info.status,
            target_agent: agent,
          });
        } else {
          globalThis._voyce.$qm.emit("qmgr:SPVUnpause", {
            target_agent: agent,
          });
        }
      }
      this.status_change_info = {
        selected_users: [],
        status: "",
      };
    },
    add_queues_selected_users() {
      for (const agent of this.queue_change_info.selected_users) {
        globalThis._voyce.$qm.emit("qmgr:SPVJoinQueue", {
          target_agent: agent,
          queues: this.queue_change_info.queues,
        });

      }
      this.queue_change_info = {
        selected_users: [],
        queues: "",
      };
      this.get_live_agent();
    },
    remove_queues_selected_users() {
      for (const agent of this.queue_change_info.selected_users) {
        globalThis._voyce.$qm.emit("qmgr:SPVLeaveQueue", {
          target_agent: agent,
          queues: this.queue_change_info.queues,
        });

      }
      this.queue_change_info = {
        selected_users: [],
        queues: "",
      };
      this.get_live_agent();
    },
    add_queue(queue) {
      // console.log("add_queue", queue);
      globalThis._voyce.$qm.emit("qmgr:SPVJoinQueue", {
        target_agent: this.selected_agent.Username,
        queues: [queue[queue.length - 1].internal_name],
      });
      this.get_live_agent();

    },
    remove_queue(queue) {
      // console.log("remove_queue", queue.internal_name);
      globalThis._voyce.$qm.emit("qmgr:SPVLeaveQueue", {
        target_agent: this.selected_agent.Username,
        queues: [queue.internal_name],
      });
      this.get_live_agent();

    },
    change_user_state() {
      if (this.current_reason != "Available") {
        globalThis._voyce.$qm.emit("qmgr:SPVPause", {
          reason: this.current_reason,
          target_agent: this.selected_agent.Username,
        });
      } else {
        globalThis._voyce.$qm.emit("qmgr:SPVUnpause", {
          target_agent: this.selected_agent.Username,
        });
      }
      this.get_live_agent();

    },
    async showDetails(item) {

      try {
        this.is_progress = true;

        var user = (
          await this.$http_in.get(`spv/v1/User/${item.Identifier}`)).data;

        this.selected_agent = item;
        this.selected_agent = {
          ...this.selected_agent,
          ...user,
          shift: this.shifts.find(e => e.user == item.Identifier)
        }

        var response = (
          await this.$http_in.get(`qmgr/v1/LiveAgent/${item.Username}`)
        ).data;

        try {
          let callResponseCounts = {
            totalDialerCalls: (response.AcceptedCalls + response.AcceptedDialerCalls + response.MissedDialerCalls),
            totalCallsWithOutbound: (response.AcceptedCalls + response.AcceptedDialerCalls + response.MissedDialerCalls + response.SuccessfulOutboundCalls),
            totalCallsWithOutboundFailures: (response.AcceptedCalls + response.AcceptedDialerCalls + response.SuccessfulOutboundCalls + response.FailedOutboundcalls)
          };

          this.performance_info.totalCallTime.value = this.toHHMMSS(response.Productivity.InInteraction);
          this.performance_info.avgCallTime.value = this.toHHMMSS(Math.round(response.Productivity.InInteraction / safeDivision(callResponseCounts.totalCallsWithOutbound)));
          this.performance_info.avgAnswerTime.value = callResponseCounts.totalDialerCalls === 0 ? '00:00:00' : this.toHHMMSS(Math.round(response.Productivity.AgentRinging / callResponseCounts.totalDialerCalls));
          this.performance_info.avgHoldTime.value = this.toHHMMSS(Math.round(response.Productivity.HoldTime / safeDivision(callResponseCounts.totalCallsWithOutbound)));
          this.performance_info.avgAcwTime.value = this.toHHMMSS(Math.round(response.Productivity.ACW / safeDivision(callResponseCounts.totalCallsWithOutboundFailures)));


        } catch (error) {
          console.error(error);
        }


        // let in_dialer_sum = (response.AcceptedCalls + response.AcceptedDialerCalls + response.MissedDialerCalls + response.MissedCalls);
        // this.performance_info.totalCallTime.value = this.toHHMMSS(response.Productivity.InInteraction);
        // this.performance_info.avgCallTime.value = this.toHHMMSS(Math.round(response.Productivity.InInteraction / (response.AcceptedCalls + response.AcceptedDialerCalls + response.SuccessfulOutboundCalls)));
        // this.performance_info.avgAnswerTime.value = in_dialer_sum == 0 ? '00:00:00' : this.toHHMMSS(Math.round(response.Productivity.AgentRinging / in_dialer_sum));
        // this.performance_info.avgHoldTime.value = this.toHHMMSS(response.Productivity.HoldTime);
        // this.performance_info.avgAcwTime.value = this.toHHMMSS(response.Productivity.ACW);

        this.selected_agent.details = {
          productivity: response.Productivity.calculated || 0,
          inbound_success: response.AcceptedCalls || 0,
          inbound_failed: response.MissedCalls || 0,
          outbound_success: ((response.SuccessfulOutboundCalls ?? 0) + (response?.AcceptedDialerCalls ?? 0)),
          outbound_failed: response.FailedOutboundcalls || 0,
        };
        this.selected_agent.details.total_break_time = globalThis.projects.find(
          (e) => e.internal_name == globalThis.user.selected_project
        ).break_time;
        this.selected_agent.details.break_time = 0;
        for (const item of Object.keys(response.ReasonLengths)) {
          if (item == "") {
            continue;
          }
          let _statu = globalThis.statuses.find((e) => e.display_name == item);
          if (_statu && _statu.show_in_dashboard) {
            if (
              globalThis.status_categories.find(
                (e) => e._id == _statu.status_category_id
              ).internal_name == "break"
            ) {
              this.selected_agent.details.break_time +=
                response.ReasonLengths[item];
            }
          }
        }
        this.selected_agent.details.fc_data = [];
        this.selected_agent.details.total_finish_code_counts = 0;
        for (const item of Object.keys(response.FinishCodeCounts)) {
          this.selected_agent.details.total_finish_code_counts +=
            response.FinishCodeCounts[item];
        }

        for (const item of Object.keys(response.FinishCodeCounts)) {
          let _fc = globalThis.finish_codes.find((j) => j.finish_code == item);
          if (_fc && !_fc.show_in_dashboard) {
            let _other = this.selected_agent.details.fc_data.find(
              (e) => e.finish_code == "Diğer"
            );
            if (_other != undefined) {
              _other.count += response.FinishCodeCounts[item];
              _other.rate += response.FinishCodeCounts[item];
            } else {
              this.selected_agent.details.fc_data.push({
                finish_code: "Diğer",
                count: response.FinishCodeCounts[item],
                rate: response.FinishCodeCounts[item],
                category: globalThis._lang('t_undefined'),
              });
            }
          } else {
            if (_fc) {
              this.selected_agent.details.fc_data.push({
                finish_code: item.replace(/ /g, "\u00a0"),
                count: response.FinishCodeCounts[item],
                rate: response.FinishCodeCounts[item],
                category: globalThis.finish_categories.find(
                  (e) => e._id == _fc.category_id
                ).category,
              });
            }
          }
        }

        if (
          this.selected_agent.details.total_break_time <=
          this.selected_agent.details.break_time
        ) {
          this.selected_agent.details.remaining_break_time = this.toHHMMSS(0);
          this.selected_agent.details.break_time_fraud = true;
        } else {
          this.selected_agent.details.remaining_break_time = this.toHHMMSS(
            this.selected_agent.details.total_break_time -
            this.selected_agent.details.break_time
          );
          this.selected_agent.details.break_time = this.toHHMMSS(
            this.selected_agent.details.break_time
          );
          this.selected_agent.details.break_time_fraud = false;
        }
        this.selected_agent.details.break_time = this.toHHMMSS(
          this.selected_agent.details.break_time
        );

        //STATUS
        this.st_data = [];
        for (const item of Object.keys(response.ReasonLengths)) {
          if (['Offline', ''].includes(item)) {
            continue;
          }
          let _st = globalThis.statuses.find((j) => j.display_name == item);
          if (_st && !_st.show_in_dashboard) {
            let _other = this.st_data.find((e) => e.display_name == "Other");
            if (_other != undefined) {
              _other.time += response.ReasonLengths[item];
            } else {
              this.st_data.push({
                display_name: "Other",
                time: response.ReasonLengths[item],
                category: globalThis._lang('t_undefined'),
              });
            }
          } else {
            if (_st) {
              this.st_data.push({
                display_name: item,
                time: response.ReasonLengths[item],
                category: globalThis.status_categories.find(
                  (e) => e._id == _st.status_category_id
                ).display_name,
              });
            }
          }
        }

        this.current_reason = item.Reason;
        this.modal_agent_details = true;
        this.filter_values.selectedQueues = item.Queues.Value.filter(
          (e) => e && !e.includes("personal")
        );

        try {
          let _user = this.users.find(e => e.username == this.selected_agent.Username);
          globalThis.chat_user = _user;
          globalThis.spv_external_chat.set_conf();
        } catch (error) {

        }
      } catch (error) {
        console.log("Error", error.message)

      } finally {
        this.is_progress = false;
      }



    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)
      this.selected_queues = [];
      this.selected_queues = Array.from(
        new Set([...this.selected_queues, ...queues])
      );
      this.get_live_agent();
      this.get_live_queue();
    },
    async abandon_call_details() {
      this.isBusy = true;
      this.interaction_details_fields = [
        { label: globalThis._lang("t_date"), key: "date", sortable: true },
        { label: globalThis._lang("t_number"), key: "phone", sortable: true },
        { label: globalThis._lang("t_queue"), key: "queue", sortable: true },
        { label: globalThis._lang("t_waitTime"), key: "time", sortable: true },
        { label: "Geri Arama", key: "is_reached", sortable: true },
        { label: "Görüşen", key: "call_back_agent", sortable: true },
      ];
      var abandoned_list = (
        await this.$http_in.post(`spv/v1/AbandonCallDetails`, {
          phone_list: this.interaction_details_data.map((e) => e.phone),
        })
      ).data;
      for (const item of this.interaction_details_data) {
        console.log("item", item);
        let tmp = abandoned_list.filter(
          (e) =>
            e.StartTime > item.timestamp && e.BaseRemoteNumber == item.phone
        );
        console.log("tmp", tmp);

        item._rowVariant = tmp.length > 0 ? "success" : "danger";
        item.is_reached = tmp.length > 0 ? true : false;
        item.call_back_agent = tmp.length > 0 ? tmp[0].Agent : '';
      }
      this.interaction_details_data = [...this.interaction_details_data];
      this.isBusy = false;
    },
    get_interaction_details: async function (type) {
      try {
        if (!['waiting_interactions', 'abandoned_interactions'].includes(type)) {
          return;
        }
        this.is_progress = true;
        this.isBusy = true;

        this.interaction_details_data = [];
        // console.log("this.wait_time_interval", this.wait_time_interval);
        clearInterval(this.wait_time_interval);
        // console.log("this.wait_time_interval2", this.wait_time_interval);

        var response = (await this.$http_in.get(`qmgr/v1/LiveQueue`)).data;

        // console.log("type", type)
        // console.log("get_interaction_details", response)
        for (const queue of response) {
          // console.log("queue.Name", queue.Name);
          if (this.selected_queues.includes(queue.Name)) {
            if (type == "waiting_interactions") {
              this.modal_interaction_details = true;

              this.selected_interaction_type = type;
              for (const item of queue.Statistics.AwaitingInteractions) {
                this.interaction_details_data.push({
                  queue: item.Queue,
                  timestamp: new Date(item.LastEventDate + 10800000).getTime(),
                  date: new Date(item.LastEventDate + 10800000)
                    .toISOString()
                    .replace("T", " ")
                    .replace("Z", ""),
                  phone: item.Extra.RemoteNumber.substr(-11),
                  time: parseInt(
                    (new Date().getTime() - item.LastEventDate) / 1000
                  ),
                });
              }
            } else if (type == "abandoned_interactions") {
              this.modal_interaction_details = true;

              this.selected_interaction_type = type;
              // console.log("this.selected_interaction_type", this.selected_interaction_type);

              for (const item of queue.AbandonedInteractionsList.filter(
                (e) => e.QueueWaitTime != null
              )) {
                this.interaction_details_data.push({
                  queue: item.Details.Queue,
                  timestamp: new Date(item.AbandonTime).getTime(),
                  date: new Date(item.AbandonTime + 10800000)
                    .toISOString()
                    .replace("T", " ")
                    .replace("Z", ""),
                  phone: item.Details.RemoteNumber.substr(-10),
                  time: item.QueueWaitTime || 0,
                  // _rowVariant: tmp.length > 0 ? 'success' : 'danger',
                  // is_reached: tmp.length > 0 ? true : false
                });
              }
            }
          }
        }

        if (type == "waiting_interactions") {
          this.modal_interaction_details = true;

          this.wait_time_interval = setInterval(() => {
            for (const row of this.interaction_details_data) {
              row.time++;
            }
          }, 1000);
        }
        this.isBusy = false;
      } catch (error) {
      } finally {
        this.is_progress = false;
      }
    },
    get_live_queue: async function () {
      var response = (await this.$http_in.get(`qmgr/v1/LiveQueue`)).data;

      this.bar_info_clear();

      let _AwaitingInteractionsCount = 0;
      let _AbandonedCount = 0;
      var tmp_abandon = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      for (const item of response) {
        if (this.selected_queues.includes(item.Name)) {
          for (const row of item.AbandonedInteractionsList.filter(
            (e) => e.QueueWaitTime != null
          )) {
            tmp_abandon[new Date(row.AbandonTime).getHours()]++;
          }

          _AwaitingInteractionsCount += Number(
            item.Statistics.AwaitingInteractionsCount
          );
          // this.top_bar_info[4].content += Number(item.Statistics.AwaitingInteractionsCount);
          _AbandonedCount += Number(item.Statistics.AbandonedCount);
          // this.top_bar_info[5].content += Number(item.Statistics.AbandonedCount);
        }
      }

      this.top_bar_info[2].content = _AwaitingInteractionsCount;
      this.top_bar_info[5].content = _AbandonedCount;
      // this.revenueComparisonLine.series[0].data = [...tmp_abandon];

      this.top_bar_info[0].content = this.agents.filter(
        (e) => e.visible_in_reports
      ).length;
      this.top_bar_info[3].content = this.agents.filter(
        (e) => e.Status == "Available" && e.visible_in_reports
      ).length;
      this.top_bar_info[1].content = this.agents.filter(
        (e) => e.Status == "InInteraction" && e.visible_in_reports
      ).length;
      let break_count = 0;
      let paused_agent = this.agents.filter(
        (e) => e.Reason != "" && e.Status == "Paused" && e.visible_in_reports
      );
      for (const row of paused_agent) {
        if (
          this.status_categories.find(
            (j) => j._id == this.status_type[row.Reason]
          )?.internal_name == "break"
        ) {
          break_count++;
        }
      }
      console.log("break_count", break_count);
      this.top_bar_info[4].content = break_count;
    },
    get_live_agent: async function () {
      for (const interval of this.timeInterval) {
        clearInterval(interval);
      }
      this.bar_info_clear();

      var response = (await this.$http_in.get(`qmgr/v1/LiveAgent`)).data;
      // console.log("get_live_agent", response);
      this.agents = [];

      // console.log("aaa", this.visible_users);
      for (const row of response.filter(e => e != null)) {
        let _total_break_time = globalThis.projects.find(
          (e) => e.internal_name == globalThis.user.selected_project
        ).break_time;
        let user_shift = this.shifts.find(e => e.user == row.Identifier);
        if (user_shift) {
          _total_break_time = Number(user_shift.break_time) * 60;
        }
        let _break_time = 0;
        let _break_time_fraud = false;
        for (const item of Object.keys(row.ReasonLengths)) {
          let _statu = globalThis.statuses.find((e) => e.display_name == item);
          if (_statu && item != "" && _statu.show_in_dashboard) {
            if (
              globalThis.status_categories.find(
                (e) => e._id == _statu.status_category_id
              ).internal_name == "break"
            ) {
              _break_time += row.ReasonLengths[item];
            }
          }
        }
        if (_total_break_time <= _break_time) {
          _break_time_fraud = true;
        } else {
          _break_time = this.toHHMMSS(_break_time);
          _break_time_fraud = false;
        }
        _break_time = this.toHHMMSS(_break_time);

        for (const queue of row.Queues.Value) {
          if (
            this.selected_queues.includes(queue) &&
            this.selected_queues.length > 0
          ) {
            // for (let index = 0; index < 20; index++) {
            this.agents.push({
              ...row,
              visible_in_reports: this.visible_users[row.Identifier],
              time: Number((new Date() - new Date(row.LastActionDate)) / 1000),
              Productivity: `%${row.Productivity.calculated}`,
              Direction: row.CurrentInteraction ? (row.CurrentInteraction.Extra.Direction ?? "") : "",
              RemoteNumber: [
                "RingingOut",
                "InInteraction",
                "AgentRinging ",
              ].includes(row.Status)
                ? row.CurrentInteraction
                  ? row.CurrentInteraction.Extra.RemoteNumber
                  : ""
                : "",
              Queue: ["RingingOut", "InInteraction", "AgentRinging "].includes(
                row.Status
              )
                ? row.CurrentInteraction
                  ? row.CurrentInteraction.Extra.Direction == "outbound"
                    ? row.CurrentInteraction.Extra.Queue
                    : row.CurrentInteraction.Queue
                  : ""
                : "",
              Classification: this.getStatusDetails(row),
              BreakTime: _break_time,
              BreakTimeFraud: _break_time_fraud,
            });

            // }



            break;
          }
        }
      }

      if (!this.dont_show_go_home_status) {
        for (const queue of this.all_queues) {
          if (
            this.selected_queues.includes(queue.internal_name) &&
            this.selected_queues.length > 0
          ) {
            for (const agent of queue.static_users) {
              let _agent = this.agents.find(
                (e) => e.Username + "@" + e.Domain == agent
              );
              if (!_agent) {
                let _reason = "Not Connected";
                let user_shift = this.shifts.find(e => e.user == agent);
                console.log("user_shift", user_shift);
                if (user_shift) {
                  if (user_shift.paid_leave) {
                    _reason = "Paid Leave";
                  }
                  if (user_shift.unpaid_leave) {
                    _reason = "Unpaid Leave";
                  }
                }

                this.agents.push({
                  Username: agent.split("@")[0],
                  Reason: _reason,
                  Status: "",
                  visible_in_reports: this.visible_users[agent],
                  time: 0,
                  Productivity: ``,
                  RemoteNumber: "",
                  Direction: "",
                  Queue: "",
                  Classification: "",
                  Domain: globalThis.user.domain,
                  BreakTime: "00:00:00",
                  BreakTimeFraud: false,
                });
              }
            }
          }
        }
      }

      // console.log("this.agents", this.agents);
      // console.log("this.status_categories", this.status_categories);


      this.top_bar_info[0].content = this.agents.filter(
        (e) => e.visible_in_reports
      ).length;
      this.top_bar_info[3].content = this.agents.filter(
        (e) => e.Status == "Available" && e.visible_in_reports
      ).length;
      this.top_bar_info[1].content = this.agents.filter(
        (e) => e.Status == "InInteraction" && e.visible_in_reports
      ).length;

      console.log("this.status_type", this.status_type);
      console.log("BREAK TEST", this.agents.filter(
        (e) =>
          e.Status == "Paused" &&
          (this.status_categories.find((j) => j._id == this.status_type[e.Reason || "Available"])
            ? this.status_categories.find((j) => j._id == this.status_type[e.Reason || "Available"]).internal_name
            : '') == "break"
          && e.visible_in_reports
      ).length);

      this.top_bar_info[4].content = this.agents.filter(
        (e) =>
          e.Status == "Paused" &&
          (this.status_categories.find((j) => j._id == this.status_type[e.Reason || "Available"])
            ? this.status_categories.find((j) => j._id == this.status_type[e.Reason || "Available"]).internal_name
            : '') == "break"
          && e.visible_in_reports
      ).length;

      this.timeInterval.push(
        setInterval(() => {
          for (const row of this.agents) {
            row.time++;
          }
        }, 1000)
      );
    },
    GetStatus: async function () {
      var response = (await this.$http_in.get(`optmgr/v1/Status`)).data;

      this.status_type = {};
      for (const item of response.status) {
        this.status_type[item.display_name] = item.status_category_id;
      }

      this.status_categories = response.categories;
      this.spv_statuses = response.status.filter((e) => e.spv_changeable);
    },
    timer(seconds) {
      let _m = ("" + (Math.floor(seconds / 60) % 60)).padStart(2, "0");
      let _h = ("" + Math.floor(seconds / 3600)).padStart(2, "0");
      let _s = ("" + Math.floor(seconds % 60)).padStart(2, "0");
      return `${_h}:${_m}:${_s}`;
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      let p_queues = globalThis.permissions["queue"];
      let default_push = this.selected_merged_queues.length > 0 ? false : true;
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          this.merged_queues.push({
            ...item,
            _type: 'queue'
          })

          if (default_push) {
            this.selected_merged_queues.push({ ...item, _type: 'queue' });
            // this.selected_queues.push(item.internal_name);
          }
        }
      }
    },
    GetQueueGroup: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/QueueGroup`)).data;
      // console.log('queues', response);
      // let p_queues = globalThis.permissions["queue"]

      this.queue_groups = response;
      let _queue_group = response.map(function (obj) {
        return { ...obj, _type: "queue_group" };
      });
      this.merged_queues = [...this.merged_queues, ..._queue_group];


      // for (const item of response) {
      //   if (p_queues.includes(item.internal_name)) {
      //     this.queues.push(item);
      //     this.selected_queues.push(item.internal_name);
      //   }
      // }
    },
    GetAgentGroup: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/AgentGroup`)).data;

      this.agent_groups = response;
      let _agent_group = response.map(function (obj) {
        return { ...obj, _type: "agent_group" };
      });
      this.merged_queues = [...this.merged_queues, ..._agent_group];
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
    GetShift: async function () {
      var response = (await this.$http_in.get(`spv/v1/Shift`)).data;

      this.shifts = response;
    },
    getProjectsDisplayNames(pf) {
      let _projects = pf;
      let text = "";
      for (const item of _projects) {
        text +=
          this.projects.find((e) => e.internal_name == item) != undefined
            ? this.projects.find((e) => e.internal_name == item)
              .display_name + ","
            : "";

      }
      text = text.substr(0, text.length - 1);
      return text;
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;
      this.users = response;
      for (const row of response) {
        this.visible_users[row.identifier] = row.is_visible_in_live_watch;
      }
    },
    bar_info_clear() {
      // this.calls_info.waiting = 0;
      // this.calls_info.abandoned = 0;
      this.top_bar_info = [
        {
          internal_name: "total_agent",
          subtitle: globalThis._lang("t_totalAgents"),
          content: 0,
          label: "",
          iconClass: 'bi bi-people font-medium-5',
          customClass: "",
          icon: "UserIcon",
          color: "light-primary",
        },
        {
          internal_name: "incall_agent",
          subtitle: globalThis._lang("t_inCall"),
          content: 0,
          label: "",
          iconClass: 'bi bi-headset font-medium-5',
          customClass: "",
          icon: "PhoneCallIcon",
          color: "light-info",
        },
        {
          internal_name: "waiting_interactions",
          subtitle: globalThis._lang("t_callsInQueue"),
          content: this.top_bar_info[2].content,
          label: "",
          iconClass: 'bi bi-clock-history font-medium-5',
          customClass: "cursor-pointer",
          icon: "WatchIcon",
          color: "light-secondary",
        },
        {
          internal_name: "available_agent",
          subtitle: globalThis._lang("t_available"),
          content: 0,
          label: "",
          iconClass: 'bi bi-person font-medium-5',
          customClass: "",
          icon: "UserCheckIcon",
          color: "light-success",
        },

        {
          internal_name: "paused_agent",
          subtitle: globalThis._lang("t_paused"),
          content: 0,
          label: "",
          iconClass: ['bi', 'bi-cup-hot', 'font-medium-5'],
          customClass: "",
          icon: "UserXIcon",
          color: "light-warning",
        },

        {
          internal_name: "abandoned_interactions",
          subtitle: globalThis._lang("t_abandonedCount"),
          content: this.top_bar_info[5].content,
          label: "",
          iconClass: 'bi bi-telephone-x font-medium-5',
          customClass: "cursor-pointer",
          icon: "PhoneMissedIcon",
          color: "light-danger",
        },
      ];
    },
    reset_chart_data_info() {
      this.chart_data_info = {
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
      };
    },
    AgentStatusChange(data) {
      // console.log("AgentStatusChange", data);
      let _user = this.agents.find((e) => e.Username == data.Username);

      if (_user != undefined) {
        _user.Reason = data.Reason;
        _user.Status = data.Status;
        _user.time = 0;
      }

      this.top_bar_info[0].content = this.agents.filter(
        (e) => e.visible_in_reports
      ).length;
      this.top_bar_info[3].content = this.agents.filter(
        (e) => e.Status == "Available" && e.visible_in_reports
      ).length;
      this.top_bar_info[1].content = this.agents.filter(
        (e) => e.Status == "InInteraction" && e.visible_in_reports
      ).length;
      let break_count = 0;
      let paused_agent = this.agents.filter(
        (e) => e.Reason != "" && e.Status == "Paused" && e.visible_in_reports
      );
      for (const row of paused_agent) {
        // console.log("row", row);

        if (
          this.status_categories.find(
            (j) => j._id == this.status_type[row.Reason]
          )?.internal_name == "break"
        ) {
          break_count++;
        }
      }
      this.top_bar_info[4].content = break_count;
    },
    AgentJoinedQueue(data) {
      // console.log("AgentJoinedQueueSPV", data);

      // if (this.agents.find((e) => e.Username == data.Username) == undefined) {
      //   this.agents.push({
      //     ...data,
      //     Reason: data.Reason,
      //     visible_in_reports: this.visible_users[data.Username + "@" + data.Domain],
      //     time: 0,
      //     Queue: "",
      //     RemoteNumber: "",
      //   });
      // }

      // this.top_bar_info[0].content = this.agents.filter(
      //   (e) => e.visible_in_reports
      // ).length;
      // this.top_bar_info[1].content = this.agents.filter(
      //   (e) => e.Status == "Available" && e.visible_in_reports
      // ).length;
      // this.top_bar_info[2].content = this.agents.filter(
      //   (e) => e.Status == "InInteraction" && e.visible_in_reports
      // ).length;
      // let break_count = 0;
      // let paused_agent = this.agents.filter(
      //   (e) => e.Reason != "" && e.Status == "Paused" && e.visible_in_reports
      // );
      // for (const row of paused_agent) {
      //   if (
      //     this.status_categories.find((j) => j._id == this.status_type[row.Reason])
      //       ?.internal_name == "break"
      //   ) {
      //     break_count++;
      //   }
      // }
      // this.top_bar_info[3].content = break_count;
      this.get_live_agent();
    },
    AgentLeftQueue(data) {
      // console.log("AgentLeftQueueSPV", data);
      this.get_live_agent();
    },
    InteractionJoinedQueue(data) {
      if (this.selected_queues.includes(data.Queue)) {
        this.top_bar_info[2].content++;
      }
    },

    InteractionSentToAgent(data) {
      console.log(data);
      let _user = this.agents.find((e) => e.Username == data.Agent);

      if (_user != undefined) {
        _user.RemoteNumber = data.InteractionType == 'Text' ? data.Extra.RemoteNumber : data.Extra.RemoteNumber.substr(-10);
        _user.Direction = data.Extra.Direction;
        _user.Queue =
          data.Extra.Direction == "outbound" ? data.Extra.Queue : data.Queue;
        if (data.InteractionType != 'Text') {
          if (data.Extra.Direction == "outbound") {
            _user.Classification = data.Extra.Classification;
          }
          if (data.Extra.Direction == "inbound") {
            _user.Classification = data.Extra.CallerIDName;
          }
          if (data.Extra.Direction == "dialer") {
            _user.Classification = data.Extra.LocalNumber;
          }
        }

        // _user.Classification = data.Extra.Direction == 'outbound' ? data.Extra.Classification : data.Extra.CallerIDName;
      }
    },
    TransferSentToAgent(data) {
      console.log(data);
      let _user = this.agents.find((e) => e.Username == data.To);

      if (_user != undefined) {
        _user.RemoteNumber = data.Extra.RemoteNumber.substr(-10);
        _user.Direction = data.Extra.Direction;
        _user.Queue =
          data.Extra.Direction == "outbound" ? data.Extra.Queue : data.Queue;
        if (data.Extra.Direction == "outbound") {
          _user.Classification = data.Extra.Classification;
        }
        if (data.Extra.Direction == "inbound") {
          _user.Classification = data.Extra.CallerIDName;
        }
        if (data.Extra.Direction == "dialer") {
          _user.Classification = data.Extra.LocalNumber;
        }
        // _user.Classification = data.Extra.Direction == 'outbound' ? data.Extra.Classification : data.Extra.CallerIDName;
      }
    },
    AgentAcceptedInteraction(data) {
      // console.log("this.agents", this.agents);

      if (this.selected_queues.includes(data.Queue)) {
        if (Number(this.top_bar_info[2].content) > 0) {
          this.top_bar_info[2].content--;
        }
      }
    },
    InteractionAbandoned(data) {
      // console.log("InteractionAbandoned SPV", data);
      // console.log("this.revenueComparisonLine.serie0s", this.revenueComparisonLine.series);

      if (this.selected_queues.includes(data.Queue)) {
        this.top_bar_info[5].content++;
        if (Number(this.top_bar_info[2].content) > 0) {
          this.top_bar_info[2].content--;
        }
        // ++this.revenueComparisonLine.series[0].data[
        //   new Date(data.EventTime).getHours()
        // ];
        // console.log("this.revenueComparisonLine.series", this.revenueComparisonLine.series);
        // this.revenueComparisonLine.series = [
        //   ...this.revenueComparisonLine.series,
        // ];
      }
    },
    InteractionRemovedFromQueue(data) {
      if (this.selected_queues.includes(data.Queue)) {
        if (Number(this.top_bar_info[2].content) > 0) {
          this.top_bar_info[2].content--;
        }
      }
    },
    InteractionSuccessful(data) {
      console.log("InteractionSuccessful", data);
      let _user = this.agents.find((e) => e.Username == data.Agent);

      if (_user != undefined) {
        _user.RemoteNumber = "";
        _user.Direction = "";
        _user.Queue = "";
        _user.Classification = "";
      }
    },
    AgentTransferRinging(data) {
      console.log("AgentTransferRinging", data);
      // let _user = this.agents.find((e) => e.Username == data.Username);
      // console.log("AgentTransferredCall_user", _user);

      // if (_user != undefined) {
      //   _user.RemoteNumber = "";
      //   _user.Queue = "";
      //   _user.Classification = "";
      // }
    },
    AgentTransferredCall(data) {
      console.log("AgentTransferredCall", data);
      let _user = this.agents.find((e) => e.Username == data.Username);
      console.log("AgentTransferredCall_user", _user);

      if (_user != undefined) {
        _user.RemoteNumber = "";
        _user.Direction = "";
        _user.Queue = "";
        _user.Classification = "";
      }
    },
    AgentLostCall(data) {
      console.log("AgentLostCall", data);
      let _user = this.agents.find((e) => e.Username == data.Username);

      if (_user != undefined) {
        _user.RemoteNumber = "";
        _user.Direction = "";
        _user.Queue = "";
        _user.Classification = "";
      }
    },
  },
  mounted: async function () {
    globalThis.spv_dashboard = this;

    if (globalThis.wallboard) {
      clearInterval(globalThis.wallboard.interval1);
      clearInterval(globalThis.wallboard.interval2);
      clearInterval(globalThis.wallboard.interval3);
    }

    // const this_data = JSON.parse(localStorage.getItem("spv_dashboard_cache"));

    // if (![undefined, null].includes(this_data)) {
    //   this.selected_queues = this_data.selected_queues;
    //   this.selected_queue_group = this_data.selected_queue_group;
    // }
    await this.GetStatus();
    await this.GetQueues();
    await this.GetQueueGroup();
    await this.GetAgentGroup();

    let spvQueues = JSON.parse(localStorage.getItem("spvQueues"));
    if (![undefined, null].includes(spvQueues)) {
      let _data = spvQueues.find((e) => e.username == globalThis.username);
      if (![undefined, null].includes(_data)) {
        // this.selected_queues = _data.selected_queues;
        // this.selected_queue_group = _data.selected_queue_group;
        for (const item of _data.selected_merged_queues.filter(key => key._type == 'queue_group')) {
          let qg = this.queue_groups.find(k => k.internal_name == item.internal_name)
          if (!qg) {
            _data.selected_merged_queues = _data.selected_merged_queues.filter(j => j.internal_name != item.internal_name)
          }
        }
        this.selected_merged_queues = _data.selected_merged_queues || [];
      }
    }


    await this.get_users();
    await this.GetProjects();
    await this.GetShift();

    await this.get_live_queue();
    await this.get_live_agent();



    this.live_interval = setInterval(() => {
      this.get_live_agent();
      this.get_live_queue();
    }, 5 * 60 * 1000);
  },
  beforeDestroy: function () {
    let _spvQueues = JSON.parse(localStorage.getItem("spvQueues"));
    if (![undefined, null].includes(_spvQueues)) {
      let _data = _spvQueues.find((e) => e.username == globalThis.username);
      if (![undefined, null].includes(_data)) {
        _data.selected_merged_queues = this.selected_merged_queues;
        _data.selected_queues = this.selected_queues;
        _data.selected_queue_group = this.selected_queue_group;
      } else {
        _spvQueues.push({
          username: globalThis.username,
          selected_queues: this.selected_queues,
          selected_merged_queues: this.selected_merged_queues,
          selected_queue_group: this.selected_queue_group,
        });
      }
    } else {
      _spvQueues = [];
      _spvQueues.push({
        username: globalThis.username,
        selected_queues: this.selected_queues,
        selected_merged_queues: this.selected_merged_queues,
        selected_queue_group: this.selected_queue_group,
      });
    }

    localStorage.setItem("spvQueues", JSON.stringify(_spvQueues));

    clearInterval(this.chart_timer);
    clearInterval(this.data_timer);
    clearInterval(this.live_interval);
    for (const interval of this.timeInterval) {
      clearInterval(interval);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style >
.voice-animation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  height: 50px;
}

.wave {
  width: 0.25rem;
  height: 2rem;
  background-color: #7367f0;
  animation: waveAnimation 1s infinite ease-in-out;
}

@keyframes waveAnimation {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}
</style>
